import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APIServices from "../../services/APIServices";
import Loader from "../../components/Loader";
import ResponsivePagination from "react-responsive-pagination";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import { PAGE_LIMIT, selectLoader } from "../../utils/Helpers";

const BlogCategoryList = () => {
  const [blogCategoryList, setBlogCategoryList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0); // No need to use setTimeout for this
    fetchBlogCategoryList(1);
  }, []);

  const fetchBlogCategoryList = async (page) => {
    setTableLoading(true);
    try {
      const response = await APIServices.get(
        `/blog-categories?page=${page}&limit=${PAGE_LIMIT}`
      );
      if (response) {
        setBlogCategoryList(response.blogCategories);
        setFilteredCategories(response.blogCategories);
        setCurrentPage(response.currentPage);
        setTotalPages(response.totalPages);
      }
    } catch (err) {
      setError("Failed to fetch blog categories.");
      console.error("Error fetching blog categories:", err);
    } finally {
      setLoading(false);
      setTableLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await APIServices.delete(`/blog-categories/${id}`);
        appToast("Category deleted successfully", TOAST_TYPE.SUCCESS);
        fetchBlogCategoryList(); // Refresh the list after deletion
      } catch (error) {
        console.error("Error deleting category:", error);
        appToast("Failed to delete category", TOAST_TYPE.ERROR);
      }
    }
  };

  const handlePageChange = useCallback(
    (page) => {
      if (page && page !== currentPage) {
        fetchBlogCategoryList(page);
      }
    },
    [currentPage]
  );

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = blogCategoryList.filter((category) =>
      category.name.includes(value)
    );
    setFilteredCategories(filtered);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="content adminUser eventtype">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-top">
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search Name"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                      <div className="input-group-append">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                    <div className="btn-group-right">
                      <Link to="/blog-category/add">
                        <button
                          type="button"
                          className="btn btn-blue btn-block"
                        >
                          + ADD CATEGORY
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped table-bordered"
                      cellSpacing="0"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>NO.</th>
                          <th colSpan={4} style={{ width: "600px" }}>
                            Category Name
                          </th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          display: tableLoading
                            ? "table-caption"
                            : "table-row-group",
                        }}
                      >
                        {tableLoading ? (
                          selectLoader(50)
                        ) : filteredCategories.length > 0 ? (
                          filteredCategories.map((blogCategory, index) => (
                            <tr key={blogCategory._id}>
                              <td>{index + 1}</td>
                              <td colSpan={4} style={{ width: "600px" }}>
                                {blogCategory.name}
                              </td>
                              <td>
                                <ul className="actions">
                                  {/* <li>
                                    <Link to={`/blog-category/edit/${blogCategory._id}`}>
                                      <button className='btn btn-eye'>
                                        <i className='fas fa-eye'></i>
                                      </button>
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Link
                                      to={`/blog-category/edit/${blogCategory._id}`}
                                    >
                                      <button className="btn btn-pen">
                                        <i className="fas fa-pen"></i>
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <button
                                      className="btn btn-trash"
                                      onClick={() =>
                                        handleDelete(blogCategory._id)
                                      }
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No categories found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogCategoryList;
