import React, { Suspense, useContext } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import { useAuth } from "./hooks/useAuth";
import DashboardLayout from "./layouts/DashboardLayout";
import AddBlog from "./pages/blogs/AddEditBlog";
import BlogList from "./pages/blogs/BlogList";
import BlogCategoryList from "./pages/blogcategory/BlogCategoryList";
import AddEditBlogCategory from "./pages/blogcategory/AddEditBlogCategory";
import BlogTopicList from "./pages/blogtopic/BlogTopicList";
import AddEditBlogTopic from "./pages/blogtopic/AddEditBlogTopic";
import { AuthContext } from "./contexts/AuthContext";
import Loader from "./components/Loader";

const RequireAuth = ({ children }) => {
  const { token } = useAuth(AuthContext);
  if (!token) {
    return <Navigate to={"/login"} />;
  }
  return (
    <>
      <DashboardLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </>
  );
};
const RoutesComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<RequireAuth />}>
          <Route index  path="/" element={<Navigate to="/blogs" replace />} />
            <Route path="blogs" element={<BlogList />} />
            <Route path="blogs/edit/:slug" element={<AddBlog />} />
            <Route path="blogs/add" element={<AddBlog />} />
            <Route path="blog-category" element={<BlogCategoryList />} />
            <Route path="blog-category/add" element={<AddEditBlogCategory />} />
            <Route
              path="blog-category/edit/:id"
              element={<AddEditBlogCategory />}
            />
            <Route path="blog-tag" element={<BlogTopicList />} />
            <Route path="blog-tag/add" element={<AddEditBlogTopic />} />
            <Route path="blog-tag/edit/:id" element={<AddEditBlogTopic />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
