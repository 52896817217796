import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import APIServices from "../../services/APIServices";
import Loader from "../../components/Loader";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import {
  capitalizeFirstLetter,
  PAGE_LIMIT,
  selectLoader,
} from "../../utils/Helpers";

const BlogList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState(null);
  const [blogsList, setBlogsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogList(currentPage);
  }, [currentPage]);

  const fetchBlogList = async (page) => {
    setTableLoading(true);
    try {
      const response = await APIServices.get(
        `/blogs?page=${page}&limit=${PAGE_LIMIT}`
      );
      if (response) {
        setCurrentPage(response.currentPage);
        setTotalPages(response.totalPages);
        setBlogsList(response.blogs);
      }
    } catch (err) {
      setError("Failed to fetch blog categories.");
      console.error("Error fetching blog categories:", err);
    } finally {
      setLoading(false);
      setTableLoading(false);
    }
  };

  const handlePageChange = useCallback(
    (page) => {
      if (page && page !== currentPage) {
        fetchBlogList(page);
      }
    },
    [currentPage]
  );

  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const filteredBlogs = useMemo(
    () =>
      blogsList?.filter((blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [blogsList, searchQuery]
  );

  const handleEditClick = useCallback(
    (slug) => {
      navigate(`edit/${slug}`);
    },
    [navigate]
  );

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this Blog?")) {
      try {
        await APIServices.delete(`/blogs/${id}`);
        appToast("Blog deleted successfully", TOAST_TYPE.SUCCESS);
        fetchBlogList(1); // Refresh the list from page one after deletion
      } catch (error) {
        console.error("Error deleting blog:", error);
        appToast("Failed to delete blog", TOAST_TYPE.ERROR);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="content adminUser eventtype">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-top">
                    <div className="input-group">
                      <input
                        onChange={handleSearch}
                        type="search"
                        className="form-control"
                        placeholder="Search Name"
                      />
                      <div className="input-group-append">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>

                    <div className="btn-group-right">
                      <Link to="/blogs/add">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-blue btn-block"
                        >
                          + ADD BLOG
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped table-bordered"
                      cellSpacing="0"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>NO.</th>
                          <th colSpan={4} style={{ width: "600px" }}>
                            BLOG TITLE
                          </th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          display: tableLoading
                            ? "table-caption"
                            : "table-row-group",
                        }}
                      >
                        {tableLoading ? (
                          selectLoader(50)
                        ) : filteredBlogs.length > 0 ? (
                          filteredBlogs?.map((blog, index) => (
                            <tr key={blog._id}>
                              <td>{index + 1}</td>
                              <td colSpan={4} style={{ width: "600px" }}>
                                {blog.title}
                              </td>
                              <td>{capitalizeFirstLetter(blog.status)}</td>
                              <td>
                                <ul className="actions">
                                  <li>
                                    {/* <button
                                    className="btn btn-eye"
                                    onClick={() => {
                                      handleEditClick(blog._id);
                                    }}
                                  > */}
                                    <a
                                      href={`https://happyending.ai/#/blog/${blog.slug}`}
                                      target="_blank"
                                      className="btn btn-eye"
                                    >
                                      <i className="fas fa-eye"></i>
                                    </a>
                                    {/* </button> */}
                                  </li>
                                  <li>
                                    <button
                                      className="btn btn-pen"
                                      onClick={() => {
                                        handleEditClick(blog.slug);
                                      }}
                                    >
                                      <i className="fas fa-pen"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="btn btn-trash"
                                      onClick={() => {
                                        handleDelete(blog._id);
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No blogs found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogList;
