import React, { useEffect, useState } from "react";
import "../../assets/css/blog-add.css";
import APIServices from "../../services/APIServices";
import {
  fetchOptions,
  getFileNameFromUrl,
  selectLoader,
  validationFields,
} from "../../utils/Helpers";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import avatarImage from "../../assets/img/nick-user2.png";
import addImage from "../../assets/img/add-icon2.jpg";
import Editor from "../../components/Editor";

const AddBlog = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [isCatagoryLoading, setCatagoryLoading] = useState(false);
  const [isTagsLoading, setTagsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [resetData, setResetData] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [profileUrl, setProfileUrl] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [blogId, setBlogId] = useState("");
  const [slugText, setSlug] = useState("");
  const [blogStatus, setBlogStatus] = useState("");
  const [isDrafted, setDrafted] = useState(false);
  const [draftBlog, setDraftBlog] = useState(false);
  const [formData, setFormData] = useState({
    categoryId: "",
    topicIds: [],
    title: "",
    // slug: "",
    description: "",
    metaTitle: "",
    metaDesc: "",
    imageAltTag: "",
    authorName: "",
    authorDesignation: "",
    // status: draftBlog ? "drafted" : "published",
  });

  const [formErrors, setFormErrors] = useState({
    categoryId: "",
    topicIds: "",
    title: "",
    // slug: "",
    description: "",
    image: "",
    metaTitle: "",
    metaDesc: "",
    imageAltTag: "",
    authorName: "",
    authorDesignation: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCategories();
    fetchTopics();
    if (slug) {
      setSlug(slug);
      fetchBlogDetails(slug);
    }
  }, [resetData]);

  const fetchBlogDetails = async (slug) => {
    setLoading(true);
    //  this is the common function to fetch the data with the use of api url
    try {
      const blogData = await APIServices.get(`/blogs/detail/${slug}`);
      let blog = blogData.blog;
      if (blog) {
        setFormData({
          categoryId: blog.categoryId,
          topicIds: blog.topicIds || [],
          title: blog.title,
          // slug: blog.slug,
          description: blog.description,
          metaTitle: blog.metaTitle || "",
          metaDesc: blog.metaDesc || "",
          imageAltTag: blog.imageAltTag || "",
          authorName: blog.authorName || "",
          authorDesignation: blog.authorDesignation || "",
          // status: "published",
        });
        let imageName = getFileNameFromUrl(blog.image);
        setFileName(imageName);
        setProfileUrl(blog.authorImage);
        setBlogStatus(blog.status);
        setBlogId(blog._id);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  const fetchCategories = () =>
    fetchOptions(
      "/blog-categories",
      setCategories,
      setLoading,
      setError,
      "Failed to fetch blog categories."
    );
  const fetchTopics = () =>
    fetchOptions(
      "/blog-topics",
      setTopics,
      setLoading,
      setError,
      "Failed to fetch blog topics."
    );

  const updateFormState = (name, value) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormState(name, value);
  };

  const handleEditorChange = (value, name) => {
    const cleanedHtml = value.replace(/ {2}/g, "&nbsp;&nbsp;");
    updateFormState(name, cleanedHtml);
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    updateFormState(name, selectedOption ? selectedOption.value : "");
    setCatagoryLoading(false);
  };

  const handleMultiSelectChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    // Extract the value of each selected option and update the formData state
    const selectedTopicIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    updateFormState(name, selectedTopicIds);
    setTagsLoading(false);
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    let imageName = "";
    if (name == "profile") {
      if (!file) {
        setProfileImage(null);
        return;
      } else {
        const url = URL.createObjectURL(file);
        setProfileUrl(url);
        setProfileImage(file);
      }
    }
    if (name == "banner") {
      if (!file) {
        setFileName("");
        setFile(null);
        return;
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { width, height } = img;
        const isValidSize = width === 1200 && height === 800;

        setFormErrors((prevErrors) => ({
          ...prevErrors,
          image: isValidSize ? "" : "Image dimensions should be 1200x800px.",
        }));
        imageName = getFileNameFromUrl(file.name);
        setFileName(isValidSize ? imageName : null);
        setFile(isValidSize ? file : null);
      };
    }
  };

  const handleCreate = async (inputValue) => {
    setCatagoryLoading(true);
    // Optionally, you can update your categories state to include the new option
    let catagoriesData = [];
    const payload = {
      name: inputValue,
    };

    // Get the current selected options as objects
    const response = await APIServices.post("/blog-categories", payload);

    if (response && response.data) {
      formData.categoryId = response.data._id;
      catagoriesData = await fetchCategories();
    }
    const selectedOption = catagoriesData.find(
      (topic) => formData.categoryId == topic.value
    );
    handleSelectChange(selectedOption, { name: "categoryId" });
  };

  const handleMultiCreate = async (inputValue) => {
    setTagsLoading(true);
    let topicsData = [];
    const payload = {
      name: inputValue,
    };

    // Get the current selected options as objects
    const response = await APIServices.post("/blog-topics", payload);

    if (response && response.data) {
      formData.topicIds.push(response.data._id);
      topicsData = await fetchTopics();
    }

    const selectedOptions = topicsData.filter((topic) =>
      formData.topicIds.includes(topic.value)
    );

    // Add the new option to the current selection
    const updatedOptions = [...selectedOptions];

    // Call handleMultiSelectChange with the updated selection
    handleMultiSelectChange(updatedOptions, { name: "topicIds" });
  };

  const resetForm = () => {
    setResetData((prev) => !prev);
    setFormData({
      categoryId: "",
      topicIds: "",
      title: "",
      description: "",
      metaTitle: "",
      metaDesc: "",
      imageAltTag: "",
      authorName: "",
      authorDesignation: "",
    });
    setFile(null);
    setFileName(null);
    setDrafted(false);
    setBlogId("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = blogId ? `/blogs/${blogId}` : "/blogs";
    const form = new FormData();

    Object.entries(formData).forEach(([key, value]) => form.append(key, value));
    if (file) {
      form.append("image", file);
    }
    if (profileImage) {
      form.append("authorImage", profileImage);
    }
    if (draftBlog && blogStatus != "published") {
      form.append("status", "drafted");
    } else {
      form.append("status", "published");
    }

    const errors = Object.fromEntries(
      Object.entries(validationFields).filter(
        ([field]) => !formData[field] || formData[field].length == 0
      )
    );

    // Add image error if no file is provided
    if (!file && !fileName) {
      errors.image =
        formErrors?.image?.length > 0 ? formErrors.image : "Image is required";
      errors.imageAltTag = "";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return; // Stop submission if there are errors

    try {
      setLoading(true);

      let response;
      if (slug || isDrafted) {
        response = await APIServices.put(url, form, {
          "Content-Type": "multipart/form-data",
        });
      } else {
        response = await APIServices.post(url, form, {
          "Content-Type": "multipart/form-data",
        });
      }

      if (draftBlog) {
        const newSlug = response.newBlog?.slug || slugText;
        const newId = response.newBlog?._id;
        if (!slugText && newSlug) {
          setSlug(newSlug);
          setBlogId(newId);
          window.open(`https://happyending.ai/#/blog/${newSlug}`, "_blank");
        } else {
          window.open(`https://happyending.ai/#/blog/${slugText}`, "_blank");
        }
        setDrafted(true);
      } else {
        toast.success(slug ? response.message : "Blog created successfully");
        resetForm();
      }
    } catch (err) {
      setError("Failed to add the blog.");
      console.error("Error while adding this blog:", err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="row">
                <div className="col-lg-8">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="top-header-title">
                          <h1>{slug ? "Edit" : "Add"} Blog</h1>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" id="blog_id" name="id" />
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            id="blog_title"
                            value={formData.title}
                            onChange={handleInputChange}
                            placeholder="Name"
                          />
                          <label htmlFor="blog_title">Title</label>
                          {formErrors.title && (
                            <div style={{ color: "red" }}>
                              {formErrors.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-floating mb-3">
                          {/* <ReactQuill
                            // className="form-control"
                            rows="12"
                            cols="8"
                            theme="snow"
                            placeholder="Description"
                            value={formData.description}
                            onChange={(val) => {
                              handleEditorChange(val, "description");
                            }}
                          /> */}
                          <Editor
                            value={formData.description}
                            onChange={(val) => {
                              handleEditorChange(val, "description");
                            }}
                          />

                          {formErrors.description && (
                            <div style={{ color: "red" }}>
                              {formErrors.description}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            name="metaTitle"
                            className="form-control"
                            id="blog_meta_title"
                            value={formData.metaTitle}
                            onChange={handleInputChange}
                            placeholder="Name"
                          />
                          <label htmlFor="blog_meta_title">Meta title</label>
                          {formErrors.metaTitle && (
                            <div style={{ color: "red" }}>
                              {formErrors.metaTitle}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-floating mb-3">
                          <textarea
                            style={{ resize: "none", height: "100px" }}
                            placeholder="Description"
                            className="form-control blog_description"
                            name="metaDesc"
                            rows="12"
                            cols="8"
                            value={formData.metaDesc}
                            onChange={handleInputChange}
                            id="blog_meta_description"
                          ></textarea>
                          <label htmlFor="blog_meta_description">
                            Meta description
                          </label>
                          {formErrors.metaDesc && (
                            <div style={{ color: "red" }}>
                              {formErrors.metaDesc}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-lg-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            name="slug"
                            className="form-control"
                            id="blog_slug"
                            value={formData.slug}
                            onChange={handleInputChange}
                            placeholder="Name"
                          />
                          <label htmlFor="blog_slug">Slug</label>
                          {formErrors.slug && (
                            <div style={{ color: "red" }}>
                              {formErrors.slug}
                            </div>
                          )}
                        </div>
                      </div> */}
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <label>Banner Image</label>
                        <div className="upload mb-3">
                          <input
                            type="file"
                            accept="image/*"
                            name="image"
                            id="image"
                            className="form-control"
                            onChange={(e) => {
                              handleFileChange(e, "banner");
                            }}
                          />
                          {fileName && (
                            <>
                              <span
                                style={{ fontSize: "15px", marginTop: "5px" }}
                              >
                                Image Name: {fileName}
                              </span>
                              <br />
                            </>
                          )}
                          <span style={{ fontSize: "15px" }}>
                            Recommended Dimensions: 1200X800px
                          </span>
                          {formErrors.image && (
                            <div style={{ color: "red" }}>
                              {formErrors.image}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            name="imageAltTag"
                            className="form-control"
                            id="blog_image_alt_tag"
                            value={formData.imageAltTag}
                            onChange={handleInputChange}
                            placeholder="Name"
                          />
                          <label htmlFor="blog_image_alt_tag">
                            Image Alt Text
                          </label>
                          {formErrors.imageAltTag && (
                            <div style={{ color: "red" }}>
                              {formErrors.imageAltTag}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label>Category</label>
                          <CreatableSelect
                            name="categoryId"
                            className="signal-select select2 form-control"
                            value={categories.find(
                              (x) => x.value === formData.categoryId
                            )}
                            options={categories}
                            onChange={handleSelectChange}
                            onCreateOption={handleCreate}
                            isLoading={isCatagoryLoading}
                            components={{ LoadingIndicator: selectLoader }}
                            isClearable
                            style={{ width: "100%" }}
                            id="blog_category"
                          />
                          {formErrors.categoryId && (
                            <div style={{ color: "red" }}>
                              {formErrors.categoryId}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label htmlFor="blog_topic">Tags</label>
                          <CreatableSelect
                            isMulti
                            name="topicIds"
                            className="signal-select select2 form-control"
                            options={topics}
                            value={topics?.filter((option) =>
                              formData.topicIds?.includes(option.value)
                            )}
                            onChange={handleMultiSelectChange}
                            onCreateOption={handleMultiCreate}
                            isLoading={isTagsLoading}
                            components={{ LoadingIndicator: selectLoader }}
                            isClearable
                            style={{ width: "100%" }}
                            id="blog_topic"
                          />
                          {formErrors.topicIds && (
                            <div style={{ color: "red" }}>
                              {formErrors.topicIds}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <div className="form-group form-group-card-bg mb-4">
                          <div className="author-card-bg">
                            <h2>Author Details</h2>

                            <div className="avatar-details-cover">
                              <div className="avatar-details">
                                <img
                                  src={addImage}
                                  className="add-img"
                                  alt="add"
                                />

                                <img
                                  src={profileUrl || avatarImage}
                                  className="avatar-img"
                                  alt="Avatar"
                                  for="image"
                                />

                                <input
                                  type="file"
                                  accept="image/*"
                                  name="blog_image"
                                  id="image"
                                  class="upload-img"
                                  onChange={(e) => {
                                    handleFileChange(e, "profile");
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    name="authorName"
                                    className="form-control"
                                    id="blog_authorname"
                                    value={formData.authorName}
                                    onChange={handleInputChange}
                                    placeholder="Name"
                                  />

                                  <label htmlFor="blog_authorname">
                                    Author name
                                  </label>

                                  {formErrors.authorName && (
                                    <div style={{ color: "red" }}>
                                      {formErrors.authorName}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    name="authorDesignation"
                                    className="form-control"
                                    id="blog_authorDesignation"
                                    placeholder="Name"
                                    value={formData.authorDesignation}
                                    onChange={handleInputChange}
                                  />

                                  <label htmlFor="blog_authorDesignation">
                                    Designation
                                  </label>
                                  {formErrors.authorDesignation && (
                                    <div style={{ color: "red" }}>
                                      {formErrors.authorDesignation}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-section">
                      <button
                        type="submit"
                        className="btn btn-blue btn-block mt-15"
                        onClick={() => {
                          setDraftBlog(false);
                        }}
                      >
                        {slug
                          ? blogStatus == "published"
                            ? "UPDATE"
                            : "PUBLISH"
                          : "PUBLISH"}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-blue btn-white mt-15"
                        onClick={() => {
                          setDraftBlog(true);
                        }}
                      >
                        PREVIEW
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBlog;
