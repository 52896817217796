import { toast } from 'react-toastify';

export const TOAST_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warn',
  INFO: 'info',
};

// content: Should be message or html content
// type: Type should be any of TOAST_TYPE
// option: Toast configuration (optional). Checkout link to explore more option : https://fkhadra.github.io/react-toastify/introduction

const appToast = (content, type, option) => {
  if (type) return toast[type](content, option);
  else return toast(content, option); // custom toast
};

export default appToast;
