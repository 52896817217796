import React, { useState, useEffect } from 'react';
import APIServices from '../../services/APIServices';
import '../../assets/css/blog-add.css';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { useNavigate, useParams } from 'react-router-dom';

const AddEditBlogTopic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blogTopic, setBlogTopic] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({ blogTopic: "" });

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      fetchBlogTopic();
    }
  }, [id]);

  // Validation function
  const validateForm = () => {
    const newErrors = { blogTopic: "" };
    let isValid = true;

    if (!blogTopic) {
      newErrors.blogTopic = "Blog tag is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  const fetchBlogTopic = async () => {
    try {
      const response = await APIServices.get(`/blog-topics/${id}`);
      setBlogTopic(response.name);
    } catch (error) {
      console.error('Error fetching tag:', error);
      appToast('Failed to fetch tag data', TOAST_TYPE.ERROR);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    if (!blogTopic) {
      appToast('Please enter tag name', TOAST_TYPE.ERROR);
      return;
    }

    const payload = {
      name: blogTopic,
    };

    try {
      if (isEdit) {
        const response = await APIServices.put(`/blog-topics/${id}`, payload);
        appToast(response.message, TOAST_TYPE.SUCCESS);
        navigate(-1); 
      } else {
        const response = await APIServices.post('/blog-topics', payload);
        appToast(response.message, TOAST_TYPE.SUCCESS);
      }
      setBlogTopic('');
    } catch (error) {
      console.error('Error saving tag:', error);
      appToast('Failed to save tag', TOAST_TYPE.ERROR);
    }
  };

  return (
    <div>
      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='row'>
                  <div className='col-lg-8'>
                    <form id='quickForm' onSubmit={handleSubmit} enctype='multipart/form-data'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='top-header-title'>
                            <h1>{isEdit ? 'Edit Blog Tag' : 'Add Blog Tag'}</h1>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-lg-12'>
                          <div className='form-floating mb-3'>
                            <input
                              type='text'
                              value={blogTopic}
                              name='blog_topic'
                              className='form-control'
                              id='blog_topic'
                              placeholder='Name'
                              onChange={(e) => {
                                setBlogTopic(e.target.value);
                                setErrors({ ...errors, blogTopic: "" });
                              }}
                            />
                            <label htmlFor='blog_topic'>Name</label>
                            {errors.blogTopic && <div className="text-danger">{errors.blogTopic}</div>}
                          </div>
                        </div>
                      </div>

                      <button type='submit' className='btn btn-blue btn-block mt-15'>
                        {isEdit ? 'UPDATE' : 'SUBMIT'}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddEditBlogTopic;
