import { ToastContainer } from 'react-toastify';
import "./App.css";
import RoutesComponent from "./Routes";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

function App() {
  return (
    <div className="wrapper">
      <div className="App">
        <RoutesComponent />
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
}

export default App;
