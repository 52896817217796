import React, { useCallback, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for Quill
import APIServices from "../services/APIServices";
import { selectLoader } from "../utils/Helpers";

const Editor = ({ value, onChange }) => {
  let quillRef = useRef(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleImageUpload = useCallback(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append("image", file);

          const response = await APIServices.post("/blogs/upload", formData, {
            "Content-Type": "multipart/form-data",
          });
          const data = await response;
          const imageUrl = data.url; // Adjust based on your response format

          // Insert image into the editor
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, "image", imageUrl);
        } catch (error) {
          console.error("Image upload failed:", error);
        } finally {
          setLoading(false); // Stop loader
        }
      }
    };
    input.click();
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline"],
        ["link", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
      ],
      handlers: {
        image: handleImageUpload,
      },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && <div className="editor-loader">{selectLoader()}</div>}
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={modules}
        theme="snow"
        placeholder="Description"
      />
    </div>
  );
};

export default Editor;
