import React from 'react';
import '../assets/css/blog-add.css';
import logo from '../assets/img/login-logo.png';
import user1 from '../assets/img/user1.svg';
import dashboard from '../assets/img/dashboard.svg';
import category from '../assets/img/category.svg';
import topic from '../assets/img/topic.svg';
import imglogout from '../assets/img/logout.svg';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const DashboardLayout = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This goes back to the previous page
  };

  const handleLogoutClick = () => {
    logout();
    // Redirect to login page
    navigate('/');
  };

  // Determine if the current route is the root or a child route
  const isRootRoute =
    location.pathname === '/blogs' || location.pathname === '/blog-category' || location.pathname === '/blog-tag';
  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <div>
      {/* Main Sidebar Container */}
      <aside className='main-sidebar sidebar-dark-primary'>
        {/* Brand Logo */}
        <a href='#' className='brand-link'>
          <img src={logo} alt='service MVP' className='brand-image' style={{ opacity: 0.8 }} />
          <span className='brand-text font-weight-light'>
            <h2 className='logo-title'>Happy ending</h2>
          </span>
          <button className='pushmenu-icone' data-widget='pushmenu' href='#' role='button'>
            <img src={user1} alt='service MVP' className='brand-image' style={{ opacity: 0.8 }} />
          </button>
        </a>

        {/* Sidebar */}
        <div className='sidebar sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition'>
          {/* Sidebar Menu */}
          <nav className='mt-2'>
            <ul
              className='nav nav-pills nav-sidebar nav-sidebar-2 flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion='false'
            >
              <li className='nav-item'>
                <NavLink to='/blogs' className={`nav-link ${isActive('/blogs') ? 'active' : ''}`}>
                  <div className='nav-icon'>
                    <img src={dashboard} alt='Dashboard' />
                  </div>
                  <p>Blogs</p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/blog-category' className={`nav-link ${isActive('/blog-category') ? 'active' : ''}`}>
                  <div className='nav-icon'>
                    <img src={category} alt='Blog Category' />
                  </div>
                  <p>Blog Category</p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/blog-tag' className={`nav-link ${isActive('/blog-tag') ? 'active' : ''}`}>
                  <div className='nav-icon'>
                    <img src={topic} alt='Blog Topic' />
                  </div>
                  <p>Blog Tags</p>
                </NavLink>
              </li>
              <li className='nav-item logout-item'>
                <button className='nav-link' onClick={handleLogoutClick}>
                  <div className='nav-icon'>
                    <img src={imglogout} alt='Logout' />
                  </div>
                  <p>Logout</p>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
      <div className='content-wrapper addnewUsers company addNewCompany addmeeting'>
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mt-3'>
              <div className='col-sm-6'>
                <h1 className='m-0'>Blogs</h1>
              </div>
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  {!isRootRoute && (
                    <li className='breadcrumb-item back-item'>
                      <button onClick={handleBackClick} className='back-button'>
                        <i className='fa fa-arrow-left'></i>
                        <p className='back-text'>Back</p>
                      </button>
                    </li>
                  )}
                  <li className='breadcrumb-item dashboard-breadcrumb-item'>
                    <a href='#'>
                      <span>{user?.fullName?.charAt(0) || ''}</span>
                      {user?.fullName}
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
