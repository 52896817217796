import Cookies from "universal-cookie";
import { Oval } from "react-loader-spinner";
import APIServices from "../services/APIServices";

export const PAGE_LIMIT = 10;
const cookies = new Cookies();

export const getCookies = (name) => {
  return cookies.get(name);
};

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  //Application somehow unable to set cookie so storing token in sessionStorage and reading from same
  //sessionStorage.setItem(name,value)
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  try {
    if (name === "authToken")
      cookies.set(name, value, { expires: expire, path: "/", secure: true });
    else cookies.set(name, value, { expires: expire, path: "/" });
  } catch (err) {
    console.log(`Error setting cookie ${name} - value is ${value} --> ${err}`);
  }
};

export const formatSelectOptions = (
  data,
  valueKey = "_id",
  labelKey = "name"
) => {
  return data.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));
};

export const getFileNameFromUrl = (url) => {
  // Extract the last part of the URL after the last '/'
  const filename = url.substring(url.lastIndexOf("/") + 1);

  // Optionally, remove any additional unwanted characters, like " - " if needed
  const cleanFilename = filename.split(" - ").pop();
  return cleanFilename;
};

export const selectLoader = (size = 20) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
    }}
  >
    <Oval
      height={size}
      width={size}
      color="#4fa94d"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </div>
);

export const fetchOptions = async (
  endpoint,
  setOptions,
  setLoading,
  setError,
  errorMsg
) => {
  try {
    const response = await APIServices.get(endpoint);
    const options = formatSelectOptions(
      response.blogCategories || response.blogTopics || []
    );
    setOptions(options);
    return options;
  } catch (err) {
    setError(errorMsg);
    console.error(errorMsg, err);
    return [];
  } finally {
    setLoading(false);
  }
};

export const removeNbsp = (html) => {
  // Replace all occurrences of &nbsp; with a regular space
  return html?.replace(/&nbsp;/g, " ");
};

// Basic Validation
export const validationFields = {
  title: "Title is required",
  description: "Description is required",
  // slug: "Slug is required",
  metaTitle: "Meta title is required",
  metaDesc: "Meta description is required",
  imageAltTag: "Image alt text is required",
  categoryId: "Category is required",
  topicIds: "Topic is required",
  authorName: "Author name is required",
  authorDesignation: "Author designation is required",
};

export const capitalizeFirstLetter = (status) => {
  return status.charAt(0).toUpperCase() + status.slice(1);
}
