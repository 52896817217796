import React, { useState } from "react";
import "../../assets/css/style.css";
import "./login.css";
import comp1Img from "../../assets/img/comp1.png";
import loginImg from "../../assets/img/login.png";
import shapBgImg from "../../assets/img/shap-bg.png";
import loginLogoImg from "../../assets/img/login-logo2.svg";
import logoHover from "../../assets/img/login-logohover.svg";
import APIService from "../../services/APIServices";
import { useNavigate } from "react-router-dom";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import { useAuth } from "../../hooks/useAuth";
import { setCookies } from "../../utils/Helpers";

const LoginPage = () => {
  let navigate = useNavigate();
  const { refreshToken } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });

  // Validation function
  const validateForm = () => {
    const newErrors = { email: "", password: "" };
    let isValid = true;

    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    try {
      const loginData = { email, password };
      const res = await APIService.post("/login", loginData);

      if (res.token) {
        refreshToken(res.token);
        setCookies("authToken", res.token);
        setTimeout(() => {
          navigate(`/blogs`);
        }, 100);
      } else {
        appToast("Login failed", TOAST_TYPE.ERROR);
      }
    } catch (error) {
      appToast("An error occurred during login", TOAST_TYPE.ERROR);
    }
  };

  return (
    <div className="hold-transition login-page">
      <section className="login_section">
        <div className="login_inner">
          <div className="login_left">
            <div className="image_element">
              <iframe
                src="https://lottie.host/embed/96894531-8aa5-4a98-a2bf-0781322d6eb5/rN2tVLe271.json"
                className="img-gif"
              ></iframe>
              <img src={loginImg} alt="sign img" />
              <img src={shapBgImg} alt="shap bg" className="shap-bg" />
            </div>
          </div>
          <div className="form_right">
            <div className="login-box">
              <div className="login-logo">
                <a className="happyLogo-cover" href="index.html">
                  <img
                    src={loginLogoImg}
                    alt="Happy Ending"
                    className="happy-logo"
                  />
                  <img
                    src={logoHover}
                    alt="Happy Ending"
                    className="happy-logo2"
                  />
                </a>
              </div>
              <div className="card">
                <div className="card-body login-card-body">
                  <p className="login-box-msg">Happy ending</p>
                  <p className="login-box-smalltext">
                    Please login to continue to your account.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="row form-floating-tab1 on">
                      <div className="col-lg-12 mb-3">
                        <div className="input-group ">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrors({ ...errors, email: "" }); // Clear email error on change
                            }}
                          />
                        </div>
                        {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="input-group ">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            id="password-field"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setErrors({ ...errors, password: "" }); // Clear password error on change
                            }}
                          />
                          <span
                            toggle="#password-field"
                            className={`fa fa-fw ${
                              showPassword ? "fa-eye-slash" : "fa-eye"
                            } field-icon toggle-password`}
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            style={{ cursor: "pointer" }}
                          ></span>
                        </div>
                        {errors.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn-black btn-block">
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <div className="or-login">or Log-in with</div>
                  <div className="social-link">
                    <a href="#">LinkedIn</a>
                    <a href="#">Facebook</a>
                    <a href="#">Google</a>
                  </div>
                  <p className="text-center-account">
                    Don’t have an account?{" "}
                    <a  className="register">
                      Register!
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
