import React, { useState, useEffect } from 'react';
import APIServices from '../../services/APIServices';
import '../../assets/css/blog-add.css';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { useNavigate, useParams } from 'react-router-dom';

const AddEditBlogCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blogCategory, setBlogCategory] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({ blogCategory: "" });
  
  useEffect(() => {
    if (id) {
      setIsEdit(true);
      fetchBlogCategory();
    }
  }, [id]);

  const fetchBlogCategory = async () => {
    try {
      const response = await APIServices.get(`/blog-categories/${id}`);
      setBlogCategory(response.name);
    } catch (error) {
      console.error('Error fetching category:', error);
      appToast('Failed to fetch category data', TOAST_TYPE.ERROR);
    }
  };

  // Validation function
  const validateForm = () => {
    const newErrors = { blogCategory: "" };
    let isValid = true;

    if (!blogCategory) {
      newErrors.blogCategory = "Blog category is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const payload = {
      name: blogCategory,
    };

    try {
      if (isEdit) {
        const response = await APIServices.put(`/blog-categories/${id}`, payload);
        appToast(response.message, TOAST_TYPE.SUCCESS);
        navigate(-1); 
      } else {
        const response = await APIServices.post('/blog-categories', payload);
        appToast(response.message, TOAST_TYPE.SUCCESS);
      }
      setBlogCategory('');
    } catch (error) {
      console.error('Error saving category:', error);
      appToast('Failed to save category', TOAST_TYPE.ERROR);
    }
  };

  return (
    <div>
      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='row'>
                  <div className='col-lg-8'>
                    <form id='quickForm' onSubmit={handleSubmit} enctype='multipart/form-data'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='top-header-title'>
                            <h1>{isEdit ? 'Edit Blog Category' : 'Add Blog Category'}</h1>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-lg-12'>
                          <div className='form-floating mb-3'>
                            <input
                              type='text'
                              value={blogCategory}
                              name='blog_category'
                              className='form-control'
                              id='blog_category'
                              placeholder='Name'
                              onChange={(e) => {
                                setBlogCategory(e.target.value);
                                setErrors({ ...errors, blogCategory: "" });
                              }}
                            />
                            <label htmlFor='blog_category'>Name</label>
                            {errors.blogCategory && <div className="text-danger">{errors.blogCategory}</div>}
                          </div>
                        </div>
                      </div>

                      <button type='submit' className='btn btn-blue btn-block mt-15'>
                        {isEdit ? 'UPDATE' : 'SUBMIT'}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddEditBlogCategory;
